@use "~booksprout-app/src/css/quasar.variables.sass" as *
@use "~booksprout-app/src/css/mixins/media.sass" as *
@import '~booksprout-app/src/css/functions/rem-fn'
@import '~booksprout-app/src/css/mixins/media'

.bs-toolbar__title-push
  margin-inline-end: rem-fn(-18) // logout button width
  padding: 0

  @include from($breakpoint-sm-min)
    margin-inline-end: rem-fn(-62) // logout button width

.bs-back
  left: rem-fn(18px) // header padding + 18px to make up 30px gap

  @include from($breakpoint-md-min)
    display: none

.bs-logout-btn
  .q-btn__content
    align-items: baseline

  @include from($breakpoint-sm-min)
    .q-icon
      transform: rotate(90deg)

.bs-spinner
  block-size: rem-fn(96)
  inline-size: rem-fn(96)
